<template>
  <div id="body">
    <div class="fatherBox">
      <div class="headerBox">
        <p>广告服务商列表</p>
        <div style="width:400px;display:flex;justify-content: flex-end;" >
        <el-button type="primary" class="btn" @click="onNavApply">认证审核</el-button>
        <el-button type="primary" class="btn" @click="dialogFormVisible = true">+ 添加广告服务商</el-button>
      </div>
      </div>
      
      <el-table :data="advUserData" v-loading="loading">
        <el-table-column prop="id" label="广告服务商编号" align="center" width="130"></el-table-column>
        <el-table-column prop="advUserName" label="广告服务商名称" align="center"> </el-table-column>
        <el-table-column prop="tel" label="联系电话" align="center"></el-table-column>
        <el-table-column prop="balance" label="余额(单位:￥)" sortable align="center">
          <template slot-scope="scope">
            <span
              style="width: 150px;text-align: center;display: inline-block;">{{ $util.func.setMoney(scope.row.balance) }}</span>
            <el-button size="mini" type="primary" style="margin-left: 5px;" @click="toRecharge(scope.row)">充值
            </el-button>
          </template>
        </el-table-column>
        <!-- <el-table-column prop="everydayBudget" label="今日预算	" align="center">
          <template slot-scope="scope">
            <span>{{ $util.func.setMoney(scope.row.everydayBudget) }}</span>
          </template>
        </el-table-column> -->
        <el-table-column label="充值记录" align="center" width="180">
          <template slot-scope="scope">
            <!-- <el-link type="primary" :underline="false" @click="onNav" style="margin-right:10px">广告主详情</el-link> -->
            <el-link type="primary" :underline="false" @click="detail(scope.$index, scope.row)">充值详情</el-link>
          </template>
        </el-table-column>
      </el-table>
      <div class="block">
        <el-pagination @current-change="handleCurrentChange" :current-page="currpage" :page-size="pagesize" background
          layout="total, prev, pager, next, jumper" :total="CountNum" style="margin-top:15px">
        </el-pagination>
      </div>
    </div>
    <el-dialog title="添加广告服务商" :visible.sync="dialogFormVisible" top="50px" :close-on-click-modal="false">
      <el-form :model="Addadvertising" ref="articleForm" style="width:460px;margin:0 auto">
        <el-form-item label="请输入广告服务商账号:">
          <el-input v-model="Addadvertising.advertising" style="width: 260px; float: left" placeholder="请输入广告服务商账号"
            clearable>
          </el-input>
        </el-form-item>
        <el-form-item style="margin-top:5px">
          <el-button @click="dialogFormVisible = false">取消</el-button>
          <el-button type="primary" @click="Adds">添加</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import {
  schoolList,
  advShangUserAdd,
  advShangUser,
  
} from '@/api/adminApi.js'
export default {
  data() {
    return {
      advUserData: [], // 广告主列表数据
      dialogFormVisible: false, // 添加广告主弹窗
      loading: false, //加载框
      pagesize: 10, // 每页显示条目个数
      currpage: 1, // 初始页
      CountNum: 0, // 总条数
      serchList: {}, //搜索栏查询
      schoolData: [], //学校集合
      stateList: [], //状态集合
      regionList: [], //地区集合
      Addadvertising: {} // 添加新的广告主
    }
  },
  mounted() {
    this.advUserList({}, 1) // 获取广告主列表
    this.schoolList() // 获取学校列表
  },
  methods: {
    onNavApply() {
      this.$router.push({name: 'advAgencyApply'})
    },
    onNav() {
      window.open('http://sygp.shiyuntech.net/')
    },
    reset() { //重置按钮
      this.serchList = {}
      this.advUserList({}, 1)
    },
    advUserList(serchJson, currpage) { // 广告主列表
      this.loading = true
      const data = serchJson
      data["page"] = currpage //	当前页数
      data["limit"] = this.pagesize // 每页条数
      advShangUser(data).then(res => {
        this.CountNum = parseInt(res.data.CountNum) // 总条数
        this.advUserData = res.data.List // 设备列表
        this.currpage = currpage //分页显示当前页
        this.loading = false
      }).catch(err => {
        console.log(err)
      })
    },
    schoolList() { // 搜索栏学校列表
      const data = {}
      data["page"] = 1 //	当前页数
      data["limit"] = 1000 // 每页条数
      schoolList(data).then(res => {
        this.schoolData = res.data.List.map((item, index) => {
          return Object.assign({}, {
            'schoolName': item.SchoolName
          }, {
            'id': item.ID
          })
        })
      }).catch(err => {
        console.log(err)
      })
    },
    detail(index, row) { // 点击充值详情
      let data = JSON.stringify(row)
      this.$router.push({
        name: 'advUserDetail',
        params: {
          detailInfo: data
        }
      })
    },
    toRecharge(row) { // 点击充值
      let data = JSON.stringify(row)
      this.$router.push({
        name: 'advUserRecharge',
        params: {
          detailInfo: data
        }
      })
    },
    Adds() { // 点击添加广告主按钮
      if (!this.Addadvertising.advertising) {
        this.$message.error('广告服务商账号不能为空')
      } else {
        const data = {
          account: this.Addadvertising.advertising
        }
        advShangUserAdd(data).then(res => {
          if (res.status.code == 1) {
            this.$message({
              message: '添加成功',
              type: 'success'
            })
            this.dialogFormVisible = false //关闭添加广告主弹窗
            this.advUserList({}, this.currpage) // 刷新
          } else {
            this.$message.error(res.status.msg)
          }
        }).catch(err => {
          console.log(err)
        })
      }
    },
    handleCurrentChange(val) { // 分页
      this.advUserList({}, val)
    }
  }
}
</script>

<style lang="scss" scoped>
.headerBox {
  display: flex;
  height: 52px;
  width: 98%;
  align-items: center;
  justify-content: space-between;
  border-bottom: 2px solid #e9e9e9;

  p {
    font-size: 16px;
    font-family: 萍方-简;
    font-weight: normal;
    color: #000000;
    margin-left: 32px;
  }

  .btn {
    display: flex;
    align-items: center;
    width: 155px;
    height: 32px;
    opacity: 1;
    border-radius: 2px;
  }
}

.query {
  width: 98%;
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding-top: 20px;
  padding-left: 32px;

  .queryLeft {
    width: 80%;
    display: flex;

    div {
      width: 22%;
      text-align: left;

      .input,
      .select {
        width: 60%;
      }
    }
  }
}
</style>